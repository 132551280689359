(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var noise = require("./noise");

var docsSlider = require("./docsSlider");

var categories = require("./categories");

var burger = require("./burger");

var favourites = require("./favourites");

var serviceTab = require("./service-tab");

var instagram = require("./instagram");

var freshPost = require("./blogFreshPost");

instagram.instagram();
docsSlider.docsSlider();
noise.noise();
categories.categories();
burger.burger();
favourites.favourites();
serviceTab.serviceTab();
freshPost.freshPost();

},{"./blogFreshPost":2,"./burger":3,"./categories":4,"./docsSlider":5,"./favourites":6,"./instagram":7,"./noise":8,"./service-tab":9}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var freshPost = exports.freshPost = function freshPost() {
  $(".blog__recently1").click(function () {
    $(".blog__fresh-post").slideToggle();
    $(".blog__fresh-post").css("display", "flex");
  });
  $(".blog__recently").click(function () {
    $(".blog__fresh-post").slideToggle();
  });
};

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var burger = exports.burger = function burger() {
  var k = 0;
  $(".mobile-nav-menu__item0").click(function () {
    $(".mobile-nav-menu__item0").addClass("mobile-nav-menu__item-active0");
    setTimeout(function () {
      window.location = $(".mobile-nav-menu__item0").attr('href');
    }, 300);
    return false;
  });
  $(".mobile-nav-menu__item1").click(function () {
    $(".mobile-nav-menu__item1").addClass("mobile-nav-menu__item-active1");
    setTimeout(function () {
      window.location = $(".mobile-nav-menu__item1").attr('href');
    }, 300);
    return false;
  });
  $(".mobile-nav-menu__item2").click(function () {
    $(".mobile-nav-menu__item2").addClass("mobile-nav-menu__item-active2");
    setTimeout(function () {
      window.location = $(".mobile-nav-menu__item2").attr('href');
    }, 300);
    return false;
  });
  $(".mobile-nav-menu__item3").click(function () {
    $(".mobile-nav-menu__item3").addClass("mobile-nav-menu__item-active3");
    setTimeout(function () {
      window.location = $(".mobile-nav-menu__item3").attr('href');
    }, 300);
    return false;
  });
  $(".mobile-nav__burger").click(function () {
    if (k === 0) {
      $(".mobile-nav-menu").slideToggle();
      $(".mobile-nav-menu").css("display", "flex");
      k = 1;
    } else {
      $(".mobile-nav-menu").slideToggle();
      k = 0;
    }
  });
  var toggles = document.querySelectorAll(".c-hamburger");

  for (var i = toggles.length - 1; i >= 0; i--) {
    var toggle = toggles[i];
    toggleHandler(toggle);
  }

  ;

  function toggleHandler(toggle) {
    toggle.addEventListener("click", function (e) {
      e.preventDefault();
      this.classList.contains("is-active") === true ? this.classList.remove("is-active") : this.classList.add("is-active");
    });
  }
};

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var categories = exports.categories = function categories() {
  //выпадающее меню
  var c = 0;
  $(".categories__item").click(function () {
    var item = $(this).html();
    $(this).parent(".catigories__list").parent(".categories").children(".categories__span").html(item);
    $(this).parent(".catigories__list").css("display", "none");
    c = 0;
  });
  $(".categories__span").click(function () {
    if (c === 0) {
      $(this).parent(".categories").children(".catigories__list").slideToggle();
      c = 1;
    } else {
      $(this).parent(".categories").children(".catigories__list").slideToggle();
      c = 0;
    }
  }); //навигация по персоналу

  $(".personal_li_item").click(function () {
    $(this).parent(".personal__ul").children().removeClass("personal_li-active");
    $(this).addClass("personal_li-active");
  }); //навигация по категориям    

  $(".tab__item").click(function () {
    $(this).parent(".service__tabs_products").children().removeClass("label-checked");
    $(this).addClass("label-checked");
  });
};

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var docsSlider = exports.docsSlider = function docsSlider() {
  var countImg = $(".docs__img").parent(".docs__wrapper").children().length;
  var nomberActive = 1;
  $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).addClass("sl_active");
  $(".docs__img").click(function () {
    nomberActive = $(this).index() + 1;
    $(".docs__slider").css("display", "flex");
    $(".docs__slider__img ").children(".sl_img").eq(0).removeClass("sl_active");
    $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).addClass("sl_active");
  });
  $(".docs__slider-close").click(function () {
    $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).removeClass("sl_active");
    $(this).parent(".docs__slider").css("display", "none");
  });
  $(".docs__slider-right").click(function () {
    $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).removeClass("sl_active");

    if (nomberActive === countImg) {
      nomberActive = 1;
    } else {
      nomberActive++;
    }

    $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).addClass("sl_active");
  });
  $(".docs__slider-left").click(function () {
    $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).removeClass("sl_active");

    if (nomberActive === 1) {
      nomberActive = countImg;
    } else {
      nomberActive--;
    }

    $(".docs__slider__img ").children(".sl_img").eq(nomberActive - 1).addClass("sl_active");
  });
};

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var favourites = exports.favourites = function favourites() {
  var a;

  var delItem = function delItem(t) {
    a.parent().parent(".favourites__product").remove();
  };

  $(".favourites_del-favour").click(function () {
    a = $(this);
    setTimeout(delItem, 200);
  });
  $(".products_add-favour").click(function () {
    $(this).css("opacity", "0.3");
  });
};

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var instagram = exports.instagram = function instagram() {
  /*
      var options = {
          horizontal: 1,
          itemNav: 'basic',
          speed: 300,
          mouseDragging: 1,
          touchDragging: 1
      };
      var frame = new Sly('.instagram__lenta', options).init();*/

  /*
  let x;
  let ml;
    $('.instagram__imgs').mousemove(function(e){           
      x = e.pageX
      ml = Number($('.instagram__imgs').css("margin-left").replace(/\D+/g,""));
      setTimeout(function tick(){
          $('.instagram__imgs').css("margin-left", (ml + x - e.pageX)+"px");     
          console.log(ml + x - e.pageX);
      }, 19);     
  });        
      */

  /*
  $('.instagram__imgs').mouseover(function(e){ 
      //x = e.pageX;
      //ml = Number($('.instagram__imgs').css("margin-left").replace(/\D+/g,""));
      console.log(e.pageX);                    
  });    */
};

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var noise = exports.noise = function noise() {
  var mas = [1, 2, 3, 4, 1];
  var i = 0;

  function loadImageAsync(url) {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.src = url;
      img.onload = resolve;
    });
  }

  Promise.all([loadImageAsync("assets/img/noise1.png"), loadImageAsync("assets/img/noise2.png"), loadImageAsync("assets/img/noise3.png"), loadImageAsync("assets/img/noise4.png")]).then(function (images) {
    setInterval(function () {
      if (i === 4) {
        i = 0;
      }

      ;
      $(".noise").addClass('noise' + mas[i + 1]);
      $(".noise").removeClass('noise' + mas[i]);
      $(".slider__noise").addClass('noise' + mas[i + 1]);
      $(".slider__noise").removeClass('noise' + mas[i]);
      i++;
    }, 60);
  });
};

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var serviceTab = exports.serviceTab = function serviceTab() {
  $(".service__1tab").click(function () {
    var id = $(this).data('id') || 0;
    var promise = new Promise(function (resolve, reject) {
      $("#tab-service-rent__description").load("assets/ajax.php", {
        action: "getIntro",
        id: id
      }, function () {
        resolve("result");
      });
    });
    promise.then(function (result) {
      $(".tab-service-rent").css("display", "block"), $(".tab-service-dry-rent").css("display", "none"), $(".tab-service-personal").css("display", "none");
    });
  });
  $(".service__2tab").click(function () {
    var id = $(this).data('id') || 0;
    var promise = new Promise(function (resolve, reject) {
      $("#tab-service-dry-rent__description").load("assets/ajax.php", {
        action: "getIntro",
        id: id
      }, function () {
        resolve("result");
      });
    });
    promise.then(function (result) {
      $(".tab-service-rent").css("display", "none"), $(".tab-service-dry-rent").css("display", "block"), $(".tab-service-personal").css("display", "none");
    });
  });
  $(".service__3tab").click(function () {
    var id = $(this).data('id') || 0;
    var promise = new Promise(function (resolve, reject) {
      $("#tab-service-personal__description").load("assets/ajax.php", {
        action: "getIntro",
        id: id
      }, function () {
        resolve("result");
      });
    });
    promise.then(function (result) {
      $(".tab-service-rent").css("display", "none");
      $(".tab-service-dry-rent").css("display", "none");
      $(".tab-service-personal").css("display", "block");
    });
  });
  /*sound rent*/

  var sound_rent = 1;

  var sound_rentF = function sound_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (sound_rent === 0) {
      $("#sound_rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      sound_rent++;
    }

    ;
    $(".sound_rent_tab").css("display", "block");
    $(".light_rent_tab").css("display", "none");
    $(".video_rent_tab").css("display", "none");
    $(".hard_rent_tab").css("display", "none");
    $(".energy_rent_tab").css("display", "none");
  };

  $("#sound_rent").click(sound_rentF);
  $("#sound_rent1").click(sound_rentF);
  var offset_sound_rent = 9;
  $("#sound_rent_more").click(function () {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_sound_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#sound_rent_more").css("opacity", "0");
      }

      $("#sound_rent_tab").append(data);
    }

    ;
    offset_sound_rent += 9;
  });
  /*-------------*/

  /*light rent*/

  var light_rent = 0;

  var light_rentF = function light_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (light_rent === 0) {
      $("#light_rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      light_rent++;
    }

    ;
    $(".sound_rent_tab").css("display", "none");
    $(".light_rent_tab").css("display", "block");
    $(".video_rent_tab").css("display", "none");
    $(".hard_rent_tab").css("display", "none");
    $(".energy_rent_tab").css("display", "none");
  };

  $("#light_rent").click(light_rentF);
  $("#light_rent1").click(light_rentF);
  var offset_light_rent = 9;
  $("#light_rent_more").click(function () {
    console.log(offset_light_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_light_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#light_rent_more").css("opacity", "0");
      }

      $("#light_rent_tab").append(data);
    }

    ;
    offset_light_rent += 9;
  });
  /*-------------*/

  /*video rent*/

  var video_rent = 0;

  var video_rentF = function video_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (video_rent === 0) {
      $("#video_rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      video_rent++;
    }

    ;
    $(".sound_rent_tab").css("display", "none");
    $(".light_rent_tab").css("display", "none");
    $(".video_rent_tab").css("display", "block");
    $(".hard_rent_tab").css("display", "none");
    $(".energy_rent_tab").css("display", "none");
  };

  $("#video_rent").click(video_rentF);
  $("#video_rent1").click(video_rentF);
  var offset_video_rent = 9;
  $("#video_rent_more").click(function () {
    console.log(offset_video_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_video_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#video_rent_more").css("opacity", "0");
      }

      $("#video_rent_tab").append(data);
    }

    ;
    offset_video_rent += 9;
  });
  /*-------------*/

  /*hard rent*/

  var hard_rent = 0;

  var hard_rentF = function hard_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (hard_rent === 0) {
      $("#hard_rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      hard_rent++;
    }

    ;
    $(".sound_rent_tab").css("display", "none");
    $(".light_rent_tab").css("display", "none");
    $(".video_rent_tab").css("display", "none");
    $(".hard_rent_tab").css("display", "block");
    $(".energy_rent_tab").css("display", "none");
  };

  $("#hard_rent").click(hard_rentF);
  $("#hard_rent1").click(hard_rentF);
  var offset_hard_rent = 9;
  $("#hard_rent_more").click(function () {
    console.log(offset_hard_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_hard_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#hard_rent_more").css("opacity", "0");
      }

      $("#hard_rent_tab").append(data);
    }

    ;
    offset_hard_rent += 9;
  });
  /*-------------*/

  /*energy rent*/

  var energy_rent = 0;

  var energy_rentF = function energy_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (energy_rent === 0) {
      $("#energy_rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      energy_rent++;
    }

    ;
    $(".sound_rent_tab").css("display", "none");
    $(".light_rent_tab").css("display", "none");
    $(".video_rent_tab").css("display", "none");
    $(".hard_rent_tab").css("display", "none");
    $(".energy_rent_tab").css("display", "block");
  };

  $("#energy_rent").click(energy_rentF);
  $("#energy_rent1").click(energy_rentF);
  var offset_energy_rent = 9;
  $("#energy_rent_more").click(function () {
    console.log(offset_energy_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_energy_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#energy_rent_more").css("opacity", "0");
      }

      $("#energy_rent_tab").append(data);
    }

    ;
    offset_energy_rent += 9;
  });
  /*------------------------------------------------------------------------------------------------*/

  /*sound dry-rent*/

  var sound_dry_rent = 1;

  var sound_dry_rentF = function sound_dry_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (sound_dry_rent === 0) {
      $("#sound_dry-rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      sound_dry_rent++;
    }

    ;
    $(".sound_dry-rent_tab").css("display", "block");
    $(".light_dry-rent_tab").css("display", "none");
    $(".video_dry-rent_tab").css("display", "none");
    $(".hard_dry-rent_tab").css("display", "none");
    $(".energy_dry-rent_tab").css("display", "none");
  };

  $("#sound_dry-rent").click(sound_dry_rentF);
  $("#sound_dry-rent1").click(sound_dry_rentF);
  var offset_sound_dry_rent = 9;
  $("#sound_dry-rent_more").click(function () {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_sound_dry_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#sound_dry-rent_more").css("opacity", "0");
      }

      $("#sound_dry-rent_tab").append(data);
    }

    ;
    offset_sound_dry_rent += 9;
  });
  /*-------------*/

  /*light dry-rent*/

  var light_dry_rent = 0;

  var light_dry_rentF = function light_dry_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (light_dry_rent === 0) {
      $("#light_dry-rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      light_dry_rent++;
    }

    ;
    $(".sound_dry-rent_tab").css("display", "none");
    $(".light_dry-rent_tab").css("display", "block");
    $(".video_dry-rent_tab").css("display", "none");
    $(".hard_dry-rent_tab").css("display", "none");
    $(".energy_dry-rent_tab").css("display", "none");
  };

  $("#light_dry-rent").click(light_dry_rentF);
  $("#light_dry-rent1").click(light_dry_rentF);
  var offset_light_dry_rent = 9;
  $("#light_dry-rent_more").click(function () {
    console.log(offset_light_dry_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_light_dry_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#light_dry-rent_more").css("opacity", "0");
      }

      $("#light_dry-rent_tab").append(data);
    }

    ;
    offset_light_dry_rent += 9;
  });
  /*-------------*/

  /*video dry-rent*/

  var video_dry_rent = 0;

  var video_dry_rentF = function video_dry_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (video_dry_rent === 0) {
      $("#video_dry-rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      video_dry_rent++;
    }

    ;
    $(".sound_dry-rent_tab").css("display", "none");
    $(".light_dry-rent_tab").css("display", "none");
    $(".video_dry-rent_tab").css("display", "block");
    $(".hard_dry-rent_tab").css("display", "none");
    $(".energy_dry-rent_tab").css("display", "none");
  };

  $("#video_dry-rent").click(video_dry_rentF);
  $("#video_dry-rent1").click(video_dry_rentF);
  var offset_video_dry_rent = 9;
  $("#video_dry-rent_more").click(function () {
    console.log(offset_video_dry_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_video_dry_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#video_dry-rent_more").css("opacity", "0");
      }

      $("#video_dry-rent_tab").append(data);
    }

    ;
    offset_video_dry_rent += 9;
  });
  /*-------------*/

  /*hard dry-rent*/

  var hard_dry_rent = 0;

  var hard_dry_rentF = function hard_dry_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (hard_dry_rent === 0) {
      $("#hard_dry-rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      hard_dry_rent++;
    }

    ;
    $(".sound_dry-rent_tab").css("display", "none");
    $(".light_dry-rent_tab").css("display", "none");
    $(".video_dry-rent_tab").css("display", "none");
    $(".hard_dry-rent_tab").css("display", "block");
    $(".energy_dry-rent_tab").css("display", "none");
  };

  $("#hard_dry-rent").click(hard_dry_rentF);
  $("#hard_dry-rent1").click(hard_dry_rentF);
  var offset_hard_dry_rent = 9;
  $("#hard_dry-rent_more").click(function () {
    console.log(offset_hard_dry_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_hard_dry_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#hard_dry-rent_more").css("opacity", "0");
      }

      $("#hard_dry-rent_tab").append(data);
    }

    ;
    offset_hard_dry_rent += 9;
  });
  /*-------------*/

  /*energy dry-rent*/

  var energy_dry_rent = 0;

  var energy_dry_rentF = function energy_dry_rentF() {
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;

    if (energy_dry_rent === 0) {
      $("#energy_dry-rent_tab").load("assets/ajax.php", {
        action: "getCollection",
        context: context,
        id: id,
        offset: 0
      });
      energy_dry_rent++;
    }

    ;
    $(".sound_dry-rent_tab").css("display", "none");
    $(".light_dry-rent_tab").css("display", "none");
    $(".video_dry-rent_tab").css("display", "none");
    $(".hard_dry-rent_tab").css("display", "none");
    $(".energy_dry-rent_tab").css("display", "block");
  };

  $("#energy_dry-rent").click(energy_dry_rentF);
  $("#energy_dry-rent1").click(energy_dry_rentF);
  var offset_energy_dry_rent = 9;
  $("#energy_dry-rent_more").click(function () {
    console.log(offset_energy_dry_rent);
    var id = $(this).data('id') || 0;
    var context = $(this).data('context') || 0;
    $.ajax({
      type: "POST",
      url: "assets/ajax.php",
      data: {
        action: "getCollection",
        context: context,
        id: id,
        offset: offset_energy_dry_rent
      },
      success: onAjaxSuccess
    });

    function onAjaxSuccess(data) {
      if (data.length < 5) {
        $("#energy_dry-rent_more").css("opacity", "0");
      }

      $("#energy_dry-rent_tab").append(data);
    }

    ;
    offset_energy_dry_rent += 9;
  });
  /*-------------*/

  $(".personal_li_item").click(function () {
    var idd = $(this).data('id') || 0;
    console.log(idd);
    $(".personal__info").load("assets/ajax.php", {
      action: "getPersonal",
      idd: idd
    });
  });
  $(".categories__item").click(function () {
    var idd = $(this).data('id') || 0;
    console.log(idd);
    $(".personal__info").load("assets/ajax.php", {
      action: "getPersonal",
      idd: idd
    });
  });
};

},{}]},{},[1]);
